import React, { Component } from 'react';
import { SendSms } from '../services/MessageService';

class Home extends Component {
    templateBreukelen = `Goedendag heer/mevrouw {{ o.LastName }},

Uw order bij Gadero wordt op dit moment klaargemaakt en kunt u afhalen VANAF vrijdagmiddag 12:00u aan de Straatweg 5 in Breukelen.

Uw afhaalnummer is {{ o.AfhaalNummer }}.

Let op: Om 12:00 is het erg druk met afhalers. Houd rekening met een wachttijd. Om de drukte te spreiden vragen wij u rond {{ date.parse '2000/01/01 12:00' | date.add_minutes ((math.floor (o.AfhaalNummer / ( math.ceil(AantalAfhalers / 10) )  )) * 30) | date.to_string "%R" }} uw bestelling af te halen.

De showroom is i.v.m. de corona-crisis gesloten. U kunt alleen uw bestelling afhalen.

Team Gadero`;

    templateGroningen = `Goedendag heer/mevrouw {{ o.LastName }},
    
Op dit moment wordt uw order bij Gadero verzameld. U kunt deze aankomende zaterdag afhalen aan de Euvelgunnerweg 19 in Groningen tussen 10.00 en 12:00.

Uw afhaalnummer is {{ o.AfhaalNummer }}.

Het wordt zaterdag erg druk. Houd rekening met een wachttijd. Om de drukte te spreiden vragen wij u rond {{ date.parse '2000/01/01 10:00' | date.add_minutes ((math.floor (o.AfhaalNummer / ( math.ceil(AantalAfhalers / 4) ) )) * 30) | date.to_string "%R" }} uw bestelling af te halen.

i.v.m. de corona-crisis zijn wij gesloten voor lokale verkoop. U kunt alleen uw bestelling afhalen.

Team Gadero`;

    templateVeldhoven = `Goedendag heer/mevrouw {{ o.LastName }},

Op dit moment wordt uw order bij Gadero verzameld. U kunt deze aankomende vrijdag afhalen aan de De Run 4444 in Veldhoven tussen 12.00 en 15:00.

Uw afhaalnummer is {{ o.AfhaalNummer }}.

Om de drukte te spreiden vragen wij u rond {{ date.parse '2000/01/01 12:00' | date.add_minutes ((math.floor (o.AfhaalNummer /  ( math.ceil(AantalAfhalers / 6) )  )) * 30) | date.to_string "%R" }} uw bestelling af te halen.

Er is geen showroom in Veldhoven. U kunt alleen uw bestelling afhalen.

Team Gadero`;

templateMeppen = `{{ if o.ClientPhone | string.starts_with("+31") }}
Goedendag heer/mevrouw {{ o.LastName }},

Op dit moment wordt uw order bij Gadero verzameld. U kunt deze aankomende vrijdag afhalen aan de Lilienthalstraße 11 in Meppen tussen 12.00 en 15:00.

Uw afhaalnummer is {{ o.AfhaalNummer }}.

Om de drukte te spreiden vragen wij u rond {{ date.parse '2000/01/01 12:00' | date.add_minutes ((math.floor (o.AfhaalNummer /  ( math.ceil(AantalAfhalers / 6) )  )) * 30) | date.to_string "%R" }} uw bestelling af te halen.

U kunt alleen uw bestelling afhalen. Wij hebben in Meppen wel een showroom, er is echter geen directe grijpvoorraad aanwezig.
{{ end }}
{{ if o.ClientPhone | string.starts_with("+49") }}
Ihre Bestellung wird derzeit bei Gadero gesammelt. Sie können Ihre Bestellung Freitag in der Zeit von 12:00 bis 15:00 Uhr an der Lilienthalstraße 11 in Meppen abholen.

Ihre Abholnummer lautet {{ o.AfhaalNummer }}.

Wir bitten Sie Ihre Bestellung ca. {{ date.parse '2000/01/01 12:00' | date.add_minutes ((math.floor (o.AfhaalNummer /  ( math.ceil(AantalAfhalers / 6) )  )) * 30) | date.to_string "%R" }} Uhr abzuholen.

Sie können Ihre Bestellung nur abholen. Wir haben nur einen Ausstellungsraum in Meppen, dort gibt es kein Lagerbestand.
{{ end }}
Team Gadero`;

templateHofstade = `Goedendag heer/mevrouw {{ o.LastName }},

Op dit moment wordt uw order bij Gadero verzameld. U kunt deze aankomende vrijdag afhalen aan de Hekkestraat 20 in Hofstade (Aalst) tussen 12.00 en 15:00.

Uw afhaalnummer is {{ o.AfhaalNummer }}.

Om de drukte te spreiden vragen wij u rond {{ date.parse '2000/01/01 12:00' | date.add_minutes ((math.floor (o.AfhaalNummer /  ( math.ceil(AantalAfhalers / 6) )  )) * 30) | date.to_string "%R" }} uw bestelling af te halen.

Er is geen showroom in Hofstade. U kunt alleen uw bestelling afhalen.

Team Gadero`;

    submit(event) {
        event.preventDefault();

        let frm = event.target.form;
        SendSms(frm.elements.TransportlijstId.value, frm.elements['Template'+frm.elements.template.value].value);
    }

    render() {
        return (<div className="container">
            <div className="panel">
                <h3>Sms portaal om afhalers een SMS te versturen</h3>
                <div className="content">

                    <form className="cols">                            
                        <div className="formgroup">
                            <label>Transportlijst Id</label>
                            <input className="extraWide" name="TransportlijstId" />
                        </div>

                        <br />
                        
                        <div className="formgroup">
                            <label htmlFor="BR">Breukelen</label>
                            <label htmlFor="GR">Groningen</label>
                            <label htmlFor="VE">Veldhoven</label>
                            <label htmlFor="ME">Meppen</label>
                            <label htmlFor="HOF">Hofstade (Aalst)</label>

                            <input id="BR" type="radio" value="BR" name="template" defaultChecked="true" className="elementToggler" />
                            <textarea name="TemplateBR" className="visibleWhenRadioChecked" defaultValue={this.templateBreukelen} />
                            
                            <input id="GR" type="radio"  name="template" value="GR"  className="elementToggler" />
                            <textarea name="TemplateGR" className="visibleWhenRadioChecked" defaultValue={this.templateGroningen} />
                            
                            <input id="VE" type="radio"  name="template" value="VE" className="elementToggler" />
                            <textarea name="TemplateVE" className="visibleWhenRadioChecked" defaultValue={this.templateVeldhoven} />                        

                            <input id="ME" type="radio"  name="template" value="ME" className="elementToggler" />
                            <textarea name="TemplateME" className="visibleWhenRadioChecked" defaultValue={this.templateMeppen} />                        

                            <input id="HOF" type="radio"  name="template" value="HOF" className="elementToggler" />
                            <textarea name="TemplateHOF" className="visibleWhenRadioChecked" defaultValue={this.templateHofstade} />                        
                        </div>

                        <button onClick={(event) => this.submit(event)}>SMS versturen</button>
                    </form>
                </div>
            </div>
            
        </div>);
    }
}

export default Home;